<script>
export default {
  methods: {
    async refreshToken() {
      try {
        const partnerUid = this.$route.params.partner_uid || ''

        // Appel à l'action Vuex pour rafraîchir le token
        await this.$store.dispatch('account/refreshToken')

        // Si le rafraîchissement du token réussit, rediriger l'utilisateur
        const redirectTo =
          this.$route.query.redirect || `/home-agence/${partnerUid}`
        this.$router.replace(redirectTo)
      } catch (error) {
        // Si le rafraîchissement échoue, rediriger vers l'écran de connexion
        this.$router.replace({ name: 'Authentication' })
      }
    },
  },
  mounted() {
    // Appeler la méthode refreshToken lors du montage du composant
    this.refreshToken()
  },
}
</script>
